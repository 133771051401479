<template>
  <div class="container kuan">
    <div class="main">
      <!-- <div class="zezhao" v-if="zezhao||weihu">
        <div class="zezhao_tip" v-if="zezhao">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}现已关盘</h2>
            <p>下期开盘时间为</p>
            <p class="green">{{kaipanTime}}</p>
          </div>
        </div>
        <div class="zezhao_tip" v-if="weihu">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}正在维护</h2>

          </div>
        </div>

      </div> -->
      <top-timer></top-timer>
      <div class="container kuan flex64">
        <div class="main">
          <div class="player listmain type-f3d f3d game-f3d game-f3d-2zh">

            <!-- <div class="kuaixuan_wrap" v-if="dop==1">
              <section class="kx_item san">

                <section class="kx_right">
                  <div class="kx_row" :class="{shang:iIndex<selNumberData.length-1,disable:item.disable}" v-for="(item,iIndex) in selNumberData" :key="item.title">
                    <em>{{item.title}}</em>
                    <span v-for="(kn,index) in item.arr" @click="setNumSel(item,kn)" :class="{selected:kn.selected}" :key="item.title+index">{{kn.num}}</span>

                
                  </div>

                </section>

              </section>

            </div>
            <div class="kuaixuan_wrap p2" v-if="dop==2">
              <section class="kx_item san">

                <section class="kx_right">
                  <div class="kx_row" :class="{shang:iIndex<selNumberData1.length-1}" v-for="(item,iIndex) in selNumberData1" :key="item.title">
                    <em>{{item.title}}</em>
                    <span v-for="(kn,index) in item.arr" @click="setNumSel1(item,kn)" :class="{selected:kn.selected}" :key="item.title+index">{{kn.num}}</span>
 
                  </div>

                </section>

              </section>

            </div> -->
          </div>
          <!-- 注单号码 -->
          <div class="player listmain  tuoduopeng" :class="dop==1?'h2zd_l':'h2zd_h'">
            <div class="data">
              <!-- <span>共 <em class="red">{{resultData.length}} </em>种组合 </span> -->
              <h3 style="text-align: left;">
                <span class="green" @click="guifan=true" style="margin: 0 10px;cursor: pointer;">规范注单</span>

<span class="red" style=";cursor: pointer;" @click="guifan=false">不规范注单</span>
              
               
              </h3>

              <!-- <div class="dantuo-li">

          </div> -->
              <div class="dantuo-sc " :class="dop==1?'h2zd_ld':'h2zd_hd'" >

                <template v-for="(item,index) in allTypeData" >
                 
                  <div :key="item.title" v-if="guifan">
                    <h4 style="color: black;">{{ item.title }}
                      <span style="margin: 0 5px;cursor: pointer;color:green" >({{ item.arr.length }})</span> 
                      <span style="color:red;cursor: pointer;" v-if="item.arr1.length>0">({{item.arr1.length }})</span>  
                     </h4>
                 
                  <span  style="color: green;" v-for="ele in item.arr" :key="ele.label">{{ele.label}}({{ ele.money }}元)</span>
               
               
                 
              
                </div>
                <div :key="item.title+index" v-if="!guifan&&item.arr1.length>0">
                    <h4 style="color: black;" >{{ item.title }}
                      <span style="margin: 0 5px;cursor: pointer;color:green" >({{ item.arr.length }})</span> 
                      <span style="color:red;cursor: pointer;" >({{item.arr1.length }})</span>  
                     </h4>
                 
                   
               
               
                  <span  v-for="ele in item.arr1" class="norule"  :key="ele">{{ele}}</span>
              
                </div>
                </template>
              
              </div>
              <!-- <p class="red"  >不符合规则注单</p>
              <div class="dantuo-sc" style="height:60px;">
                <template v-for="item in badtextArr" >
                <div :key="item.title">
                  <h5>{{ item.title }}({{ item.arr.length }})</h5>
                  <span class="norule" v-for="ele in item.arr" :key="ele">{{ ele }}</span>
                </div>
            </template>

              </div> -->
            
            </div>
            <h4 class="blue" @click="sm_bool=!sm_bool" style="cursor: pointer;">快译说明 
        <i v-if="!sm_bool" class="el-icon-arrow-down"></i>
        <i v-if="sm_bool" class="el-icon-arrow-up"></i>
      </h4>
            <div class="atten" v-if="sm_bool">
          重要提示：
          <p class="atten_flex">
            <span class="xuhao">1.</span>
          <b> 注单文本必须同时包含 玩法(直|组|复|和|跨|一定|二定)，号码，金额 三要素，号码必须通过空格，逗号间隔开，同一单里面只能包含一种玩法；金额必须为阿拉伯数字，单位可为 元、米或倍；</b>
          </p>
          <p class="atten_flex">
            <span class="xuhao">2.</span>
            <b  > 多单同时快译时，必须换行输入；</b>
          </p>
          <p class="atten_flex">
            <span class="xuhao">3.<b>使用示例 <span class="blue">蓝色字体代表下注说明</span> </b></span>
            <ul class="fuckli" style="text-indent: 32px;padding-bottom: 30px;">
              
                            <li>直 123，456，789 各5元</li>
                            <li>组 123，4156，7890 各8元 <span class="blue">(组三、组六各8元)</span>  </li>
                            <li>123 468 7654 3456各12元  <span class="blue">(组三、组六各12元)</span> </li>
                            <li>组三234，156，47890 各8元 <span class="blue">(组三各8元)</span> </li>
                            <li>组六123，4156，7890 各8元 <span class="blue">(组六各8元)</span> </li>
                            <li>和 1 3 2 6 7各3元</li>
                            <li>跨 1 3 5 9 7各3元</li>
                            <li>独 2 4 6 8 各3元</li>
                            <li>对子 2 4 6 8 各13元</li>
                            <li>对子全包13元</li>
                            <li>一定 1xx x3x 8xx xx0各9元</li>
                           <li>二定 1x1 x34 82x 9x0各10元</li>
                           <li>183 128 5单6组 <span class="blue">(直选各10元组三组六各12元)</span></li>
                           <li>123 1357 四直五组 <span class="blue">(直选各8元组三组六各10元)</span></li>
                           <li>1 0 2 8 24 八跨三和<span class="blue">(跨度各16元和值各6元)</span></li>
                          
                          
                         
                          </ul>
          </p>
        </div>
          </div>
        </div>
        <div class="other">
          <!-- <div class="zezhao" v-if="zezhao||weihu"></div> -->
          <div class="right-com">

            <div style="height:1px"></div>
            <!-- <div>
              <span class="typeitem" :class="ordertype==aa.value?'selected':''"
              @click="setOrderType(aa)"
               v-for="aa in ordertypeArr" :key="aa.id">{{ aa.name }}</span>
            </div> -->
            <h5 style="line-height: 27px;font-size: 13px;">粘贴框</h5>
          <textarea @change="scAllTypeData" class="asdefda" v-model="textcontent" placeholder="请输入下注内容"></textarea>
        
        
        
           
          
            <section  >
              
              <div  class="btnaa">
                <span  class="button"  @click="touzhon">投注</span>
                <span  class="button" @click="resetAllInput">清空</span>
              </div>
              </section>

          </div>
        </div>
      </div>
       

      <!-- <yushe /> -->
    </div>
    
  </div>
</template>

<script>
import topTimer from "../mainHeader.vue";
import yushe from "../yushe.vue";
import { mapState } from "vuex";
import kxmixins from "../mixins/kxmixinspl3";
import { zhixuan,kua,hezhi,yiziding,erziding,yizixian,zuxuan3,zuxuan6,fushi,daxiaodanshuang,duizi } from "./pl3callData.js";
import mixins from "../mixins/mainMixins";
import {md55} from '../mixins/md5.js'
export default {
  name: "kuaiyi",
  props: [""],
  mixins: [mixins,kxmixins],
  components: {
    yushe,
    topTimer,
  },
  data() {
    return {
      sm_bool:false,
      ordertype:'zhixuan',
      ordertypeArr:[{id:'t1',value:'zhixuan',name:'直选'},{id:'t2',value:'zusan',name:'组三'}
    ,{id:'t3',value:'zuliu',name:'组六'},
    {id:'t9',value:'fushi',name:'复式'},
//     {id:'t4',value:'kuadu',name:'跨度'},
// {id:'t11',value:'hezhi',name:'和值'}
// ,{id:'t12',value:'dudan',name:'独胆'},{id:'t13',value:'yiding',name:'一定'},{id:'t14',value:'erding',name:'二定'}
],
     
     textcontent:'',
     badtextArr:[],
     allTypeData:[],
     guifan:true
    };
  },
  watch: {
    rowsData: {
      deep: true,
      handler(val) {
        // console.log(val,182)
        this.$nextTick(() => {
          let arr = [];
          val.forEach((item) => {
           
              if (item.money) {
                let obj = {
                  label: item.label,
                  Odds: item.Odds,
                  title: this.ordertype=='zhixuan'?'三字定':'',
                  id: item.ResultID,
                  money: Number(item.money),
                };
                if(item.num){
                  obj.num=item.num
                }
                arr.push(obj);
              
              }
            
          });
  // console.log(arr,203)
          // console.log(arr, "arrrr");
          if (arr.length > 0) {
            this.$store.commit("setXZList", arr);
          } else {
            this.$store.commit("setXZList", []);
          }
        });
      },
    },
  
  },

  created() {
  

  },
mounted(){
 
},
  methods: {
    touzhon(){
      if(this.allTypeData.length==0){
        this.tipon( "请输入内容");
        return
      }
      this.$nextTick(()=>{
        let arr = [];
      this.allTypeData.forEach(item=>{
        item.arr.forEach(ele=>{
          let obj={
            label:ele.label,
            // title:item.title,
            id:ele.ResultID,
            money:Number(ele.money)
          }
          if(ele.num){
            obj.num=ele.num
          }
          arr.push(obj);
        })
      })
      if (arr.length > 0) {
            this.$store.commit("setXZList", arr);
            this.$store.commit("showOrHideXZTK", true);
          } else {
            this.$store.commit("setXZList", []);
          }


      })
    
   
    },
    checkCombination(str) {
      let arr1 = ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十',1,2,3,4,5,6,7,8,9,10];
let arr2 = ['直', '组', '复', '跨', '和','独'];
  // 构建正则表达式，匹配arr1和arr2中元素的所有可能组合
  let regexPattern = `(${arr1.join('|')})(${arr2.join('|')})(${arr1.join('|')})(${arr2.join('|')})`;
  let regex = new RegExp(regexPattern,'g');
  let matches = str.match(regex);
  // 使用正则表达式测试字符串
  return matches?matches:false;
},
    setOrderType(aa){
      this.textcontent=''
      this.resultData=[];
      this.badtextArr=[]
      this.ordertype=aa.value
    },
    yanzehngDuiziLabel(hmleng){
      let b=true
      if(hmleng!=1&&hmleng!=10){
        b=false
      }
      return b
    },
    yanzhengZusanLabel(hmleng,elestr){
      let b=true
      if(hmleng==1){
        b=false
      }
      // if(hmleng==3){
      //   if(elestr[0]==elestr[1]&&elestr[0]==elestr[2]){
      //      b=false
      //   }
      //  }
       if(hmleng>2){
        // 
           if (!/^(?!.*(\d).*\1)\d+$/.test(elestr)){
      b=false
     }
       }


      return b
    },
    yanzhengZuLiuLabel(hmleng,elestr){
      let b=true
      if(hmleng<2){
        b=false
      }
      // if(hmleng==3){
      //   if(elestr[0]==elestr[1]&&elestr[0]==elestr[2]){
      //     b=false
      //   }
      
      //  }
       if(hmleng>=2){
        // 
           if (!/^(?!.*(\d).*\1)\d+$/.test(elestr)){
      b=false
     }
       }


      return b
    },
    getDXDSData(){
      let transData=[];
      let strArr=this.fengeStrData(this.dxdsInput)
      strArr.forEach(elestr=>{
      
       
      // if (/^\d{2}X|\dX\d|X\d{2}$/.test(elestr)){
       

    let item=daxiaodanshuang.find(item=>item.label==elestr)
  if(item){
    let newobj=Object.assign({},item);
  
  
    transData.push(newobj);
        
  }
  
     
  //  }
   })
      return transData
    },
    getERZDData(arr){
      let transData=[];
      let badData=[]
  
      arr.forEach(elestr=>{
      
       
      if (/^\d{2}X|\dX\d|X\d{2}$/.test(elestr)){
       

    let item=erziding.find(item=>item.label==elestr)
  if(item){
    let hadIndex=transData.findIndex(eas=>eas.ResultID==item.ResultID)
      if(hadIndex==-1){
        let newobj=Object.assign({},item);
  
  
  transData.push(newobj);
      }
 
        
  }else{
    badData.push(elestr)
  }
  
     
   }else{
    badData.push(elestr)
   }
   })
      return {transData,badData}
    },
    getYZDData(arr){
      let transData=[];
     let badData=[]
     arr.forEach(elestr=>{
      
       
      if (/^(?:\d[X]{2}|[X]\d[X]|[X]{2}\d)$/.test(elestr)){
       

    let item=yiziding.find(item=>item.label==elestr)
  if(item){
    let hadIndex=transData.findIndex(eas=>eas.ResultID==item.ResultID)
      if(hadIndex==-1){
        let newobj=Object.assign({},item);
  
  
  transData.push(newobj);
      }
  
        
  }else{
    badData.push(elestr)
  }
  
     
   }else{
    badData.push(elestr)
   }
   })
      return {transData,badData}
    },

    getYZXData(arr){
      let transData=[];
      let badData=[]
      arr.forEach(elestr=>{
      
       
      if (/^\d{1}$/.test(elestr)){
       

    let item=yizixian.find(item=>item.label==elestr)
  if(item){
    let hadIndex=transData.findIndex(eas=>eas.ResultID==item.ResultID)
      if(hadIndex==-1){
        let newobj=Object.assign({},item);
  
    newobj.label='独胆'+elestr
    transData.push(newobj);
      }
  
        
  }else{
    badData.push(elestr)
  }
  
     
   }else{
    badData.push(elestr)
   }
   })
      return {transData,badData}
    },
    getHeZhiData(arr){
      let transData=[];
      let badData=[]
    
      arr.forEach(elestr=>{
      
       
        if (/^([0-9]|1\d|2[0-7])$/.test(elestr)){
         

      let item=hezhi.find(item=>item.label=='和值'+elestr)
    if(item){
      let hadIndex=transData.findIndex(eas=>eas.ResultID==item.ResultID)
      if(hadIndex==-1){
        let newobj=Object.assign({},item);
    
    
    transData.push(newobj);
      }
   
          
    }else{
      badData.push(elestr)
    }
    
       
     }else{
      badData.push(elestr)
     }
     })
      return {transData,badData}
    },
    getFushiDaata(arr){
      let transData=[];
      let badData=[]
      // console.log(strArr,425)
      arr.forEach(elestr=>{
        let hmleng=elestr.length
       
        if (/^(?!.*(\d).*\1)\d+$/.test(elestr)){
          let strarr=elestr.split('')
          let xiazhunum=strarr.join(',')

      let item=fushi.find(item=>item.label==hmleng)
    if(item){
      // let hadIndex=transData.findIndex(eas=>eas.ResultID==item.ResultID)
      // if(hadIndex==-1){
        let newobj=Object.assign({},item);
      newobj.label='复式'+hmleng+'码('+elestr+')'
      newobj.num=xiazhunum
      transData.push(newobj);
      // }
   
          
    }else{
      badData.push(elestr)
    }
    
       
     }else{
      badData.push(elestr)
     }
     })
      return {transData,badData}
    },
    getKuaduData(arr){
      let transData=[];
      let badData=[]
      arr.forEach(elestr=>{
        if (/^\d{1}$/.test(elestr)){
      let item=kua.find(item=>item.label=='跨'+elestr)
    if(item){
      let hadIndex=transData.findIndex(eas=>eas.ResultID==item.ResultID)
      if(hadIndex==-1){
        let newobj=Object.assign({},item);
      newobj.label='跨'+elestr
      transData.push(newobj);
      }
    
          
    }else{
      badData.push(elestr)
    }
    
       
     }else{
      badData.push(elestr)
     }
     })

      return {transData,badData}
    },
    getZuLiuData(arr){
      let transData=[];
      let badData=[]
      
    
      arr.forEach(elestr=>{
      if(elestr.includes('+')){
      // 胆拖  还不知道怎么写

      }else{
        // 没有+  不是胆拖  
        let hmleng=elestr.length
    let b=this.yanzhengZuLiuLabel(hmleng,elestr)
 
    if(b){
      let strarr=elestr.split('')
      let xiazhunum=strarr.join(',')
      let item=zuxuan6.find(item=>item.label==hmleng)
 
       if(item){
        // let hadIndex=transData.findIndex(eas=>eas.ResultID==item.ResultID)
        // if(hadIndex==-1){
          let newobj=Object.assign({},item);
        newobj.num=xiazhunum
        newobj.label='组六'+newobj.label+'码('+elestr+')'
        transData.push(newobj);
        // }
     
     
       }else{
        badData.push(elestr)
       }
    }else{
      badData.push(elestr)
    }

      }
  
     })
     return {transData,badData}
    },
    getDuiziData(arr){
      let transData=[];
      let badData=[]
    arr.forEach(elestr=>{
      let hmleng=elestr.length
      let b=this.yanzehngDuiziLabel(hmleng)
     if(b){
      let strarr=elestr.split('')
      let xiazhunum=strarr.join(',')
      let  item=duizi.find(item=>item.label==hmleng)
      
     if(item){
      let newobj=Object.assign({},item)
      newobj.num=xiazhunum
      if(item.label==10){
     newobj.label='对子全包'
      }else{
        newobj.label='对子('+elestr+')'
      }
      transData.push(newobj);
     }
     }else{
      badData.push(elestr)
     }

    })

   
    return {transData,badData}
     
  },
    getZusanData(arr){
      let transData=[];
      let badData=[]
     arr.forEach(elestr=>{
      if(elestr.includes('+')){
  // 胆拖  还不知道怎么写

      }else{
        // 没有+  不是胆拖  
        let hmleng=elestr.length
    let b=this.yanzhengZusanLabel(hmleng,elestr)
    if(b){
      let strarr=elestr.split('')
      let set1=new Set(strarr)
       let newarr=Array.from(set1)
     
       let item=null
       if(newarr.length==1){
        item=zuxuan3.find(item=>item.label=='双飞')
       }else{
        item=zuxuan3.find(item=>item.label==hmleng)
       }
      let xiazhunum=strarr.join(',')
       
 
       if(item){
        // let hadIndex=transData.findIndex(eas=>eas.ResultID==item.ResultID)
        // if(hadIndex==-1){
          let newobj=Object.assign({},item);
        newobj.num=xiazhunum
        if(newobj.label!='双飞'){
          newobj.label='组三'+newobj.label+'码('+elestr+')'
        }else{
          newobj.label='组三'+newobj.label+'('+elestr+')'
        }
       
        transData.push(newobj);
        // }
      
     
       }else{
        badData.push(elestr)
       }
    }else{
      badData.push(elestr)
    }

      }
  
     })
     return {transData,badData}
    },
    getZhiXuanData(arr){
    
     let transData=[];
     let badData=[]
   
    arr.forEach(elestr=>{
        if (/^\d{3}$/.test(elestr)){
      let item=zhixuan.find(item=>item.label==elestr)
    if(item){
      let hadIndex=transData.findIndex(eas=>eas.ResultID==item.ResultID)
      if(hadIndex==-1){
        transData.push(item);
      }
    
          
    }else{
      badData.push(elestr)
    }  
     }else{
      badData.push(elestr)
     }
     })
 
     

     return {transData,badData}
   

    },
    scAllTypeData(){
      if(!this.textcontent){
      // this.tipon('请输入内容')
        return
      }
      this.badtextArr=[]
      this.allTypeData=[]
      // let allTypeData=[];
      // let allBadData=[];
      let allArr = this.textcontent.split("\n");
     allArr.forEach(item=>{
      if(item.includes('单')){
        item=item.replace('单','直')
      }
    if(!this.checkCombination(item)){
      let arr2 = ['直', '组', '复', '跨', '和','组三','组六','一定','二定','独','对','对子全包'];
      let arr3 = ['复', '跨', '和','直选','组三','组六','一定','二定','独','对子','对子全包'];
      if(item.includes('组三')||item.includes('组六')||item.includes('直选')||item.includes('对子')){
        item=this.addSpacesForChars(arr3,item)
      }else{
        item=this.addSpacesForChars(arr2,item)
      }
  
    }
    let regex = /^[0-9][^直组复跨和组三组六一定二定独对子]*/;
let matched = regex.test(item);
if(matched){//没有
  let { onlyNumbers, zumoney } = this.getXiaXhuAndMoney(item,'组');
  let {transData,badData}=this.getZusanData(onlyNumbers)
        transData.forEach(ele=>{
        ele.money=Number(zumoney)
      })
      this.panduanAddOrCut('组三',JSON.parse(JSON.stringify(transData)),badData)
 //  组六
 let zuliobj=this.getZuLiuData(onlyNumbers)
      zuliobj.transData.forEach(ele=>{
        ele.money=Number(zumoney)
      })
      this.panduanAddOrCut('组六',JSON.parse(JSON.stringify(zuliobj.transData)),zuliobj.badData)

}
      // 直选
      if(item.includes('直')){
       
     
      let {onlyNumbers,zhimoney}=this.getXiaXhuAndMoney(item,'直')
     
      let {transData,badData}=this.getZhiXuanData(onlyNumbers)
      // console.log(onlyNumbers,683,zhimoney,transData)
      // 符合规则的项目
      transData.forEach(ele=>{
        ele.money=Number(zhimoney)
      })
     this.panduanAddOrCut('直选',JSON.parse(JSON.stringify(transData)) ,badData)

      }
      // ----------------
      // 组选
      if(item.includes('组')){
        let { onlyNumbers, zumoney } = this.getXiaXhuAndMoney(item,'组');
        if(item.includes('组三')){
 // 组三
 let {transData,badData}=this.getZusanData(onlyNumbers)
        transData.forEach(ele=>{
        ele.money=Number(zumoney)
      })
    
      this.panduanAddOrCut('组三',JSON.parse(JSON.stringify(transData)),badData)
        }else if(item.includes('组六')){
  //  组六
  let zuliobj=this.getZuLiuData(onlyNumbers)
      zuliobj.transData.forEach(ele=>{
        ele.money=Number(zumoney)
      })
    
      this.panduanAddOrCut('组六',JSON.parse(JSON.stringify(zuliobj.transData)) ,zuliobj.badData)
        }else{
      
          let {transData,badData}=this.getZusanData(onlyNumbers)
        transData.forEach(ele=>{
        ele.money=Number(zumoney)
      })
      this.panduanAddOrCut('组三',JSON.parse(JSON.stringify(transData)),badData)
 //  组六
 let zuliobj=this.getZuLiuData(onlyNumbers)
      zuliobj.transData.forEach(ele=>{
        ele.money=Number(zumoney)
      })
      this.panduanAddOrCut('组六',JSON.parse(JSON.stringify(zuliobj.transData)),zuliobj.badData)


        }
       
    
      }
      // 对子
      if(item.includes('对')){
        if(item.includes('全包')){
          item = item.replace("全包", "0123456789 ");
        }
        let {onlyNumbers,duimoney}=this.getXiaXhuAndMoney(item,'对')
// 
let {transData,badData}=this.getDuiziData(onlyNumbers)
// console.log(onlyNumbers,transData,839,item)
transData.forEach(ele=>{
        ele.money=Number(duimoney)
      })

      this.panduanAddOrCut('对子',JSON.parse(JSON.stringify(transData)),badData)
      }
      //------------------------------------------
      // 复式
      if(item.includes('复')){
        let { onlyNumbers, fumoney } = this.getXiaXhuAndMoney(item,'复');
        let {transData,badData}=this.getFushiDaata(onlyNumbers)
        transData.forEach(ele=>{
        ele.money=Number(fumoney)
      })
      this.panduanAddOrCut('复式',JSON.parse(JSON.stringify(transData)),badData)


      }
      // 跨
      if(item.includes('跨')){
        let { onlyNumbers, kuamoney } = this.getXiaXhuAndMoney(item,'跨');
        let {transData,badData}=this.getKuaduData(onlyNumbers)
        transData.forEach(ele=>{
        ele.money=Number(kuamoney)
      })
      this.panduanAddOrCut('跨',JSON.parse(JSON.stringify(transData)),badData)
      }
      // 和
      if(item.includes('和')){
        let { onlyNumbers, hemoney } = this.getXiaXhuAndMoney(item,'和');
        let {transData,badData}=this.getHeZhiData(onlyNumbers)
        transData.forEach(ele=>{
        ele.money=Number(hemoney)
      })
      this.panduanAddOrCut('和值',JSON.parse(JSON.stringify(transData)),badData)
      }
      // 一定
      if(item.includes('一定')){
        let { onlyNumbers, dingmoney1 } = this.getXiaXhuAndMoney(item,'一定','ding',1);
        // console.log(onlyNumbers,dingmoney1,776)
        let {transData,badData}=this.getYZDData(onlyNumbers)
       
        transData.forEach(ele=>{
        ele.money=Number(dingmoney1)
      })
      this.panduanAddOrCut('一字定',JSON.parse(JSON.stringify(transData)),badData)
      }
      // 二定
      if(item.includes('二定')){
        let { onlyNumbers, dingmoney2 } = this.getXiaXhuAndMoney(item,'二定','ding',2);
        let {transData,badData}=this.getERZDData(onlyNumbers)
        transData.forEach(ele=>{
        ele.money=Number(dingmoney2)
      })
      this.panduanAddOrCut('二字定',JSON.parse(JSON.stringify(transData)),badData)
      }
       // 独胆
       if(item.includes('独')){
        let { onlyNumbers, dumoney } = this.getXiaXhuAndMoney(item,'独');
        let {transData,badData}=this.getYZXData(onlyNumbers)
        transData.forEach(ele=>{
        ele.money=Number(dumoney)
      })
      this.panduanAddOrCut('独胆',JSON.parse(JSON.stringify(transData)),badData)
      }
//  -------------------------------

     })
  
    },
    shengcehngItem(){
      let resultData=[];
      this.badtextArr=[]
      if(!this.textcontent){
      this.tipon('请输入内容')
        return
      }
     if(this.ordertype=='zhixuan'){
     let arr=this.getZhiXuanData()
      resultData=resultData.concat(arr)
     }
     if(this.ordertype=='zusan'){
        let arr=this.getZusanData()
       resultData=resultData.concat(arr)
     }
     if(this.ordertype=='zuliu'){
      let arr=this.getZuLiuData()
       resultData=resultData.concat(arr)
     }
     if(this.ordertype=='kuadu'){
      let arr= this.getKuaduData()
       resultData=resultData.concat(arr)
     }
     if(this.ordertype=='fushi'){
      let arr=this.getFushiDaata()
       resultData=resultData.concat(arr)
     }
     if(this.ordertype=='hezhi'){
      let arr=this.getHeZhiData()
       resultData=resultData.concat(arr)
     }
     if(this.ordertype=='dudan'){
      let arr=this.getYZXData()
       resultData=resultData.concat(arr)
     }
     if(this.ordertype=='yiding'){
      let arr=this.getYZDData()
       resultData=resultData.concat(arr)
     }
     if(this.ordertype=='erding'){
      let arr=this.getERZDData()
       resultData=resultData.concat(arr)
     }
 
  
  // console.log(this.badtextArr,615)
  
  
   
  //  if(this.dxdsInput){
  //   let arr=this.getDXDSData()
  //   resultData=resultData.concat(arr)
  //  }

      console.log(resultData,246)
      this.resultData=resultData
    },
    addSpacesForChars(arr, str) {
  // 构建正则表达式，匹配数组中的每个字符
  let regexPattern = `(${arr.join('|')})`;
  let regex = new RegExp(regexPattern, 'g');

  // 使用正则表达式和替换函数来在匹配的字符前后添加空格
  return str.replace(regex, ' $1 ');
},
    getXiaXhuAndMoney( item,typestr,par3,dingnum) {
     
 
      let newStr = item.trim();
      if (newStr.includes("各")) {
        newStr = newStr.replace("各", " 各");
      }
      // if(newStr.includes('全包')){
      //   newStr = newStr.replace("全包", "0123456789");
      // }
       if(newStr.includes('x')){
        
         newStr = newStr.replace(/x/g, "X");
          
      }
     
      let strArr = newStr.split(/[，, 。. \/，·-]/);
     
     
      // 取出下注项
  let onlyNumbers=[];
  if(par3=='ding'){
    // 一字定
    if(this.containsX(item)){
      onlyNumbers=strArr.filter((item) => this.containsX(item)||this.isNumeric(item));
    
    }else if(/百|十|个/.test(item)){
      let baishigeArr= strArr.filter((item) => /百|十|个/.test(item))
      baishigeArr.forEach(bsgstr=>{
        let shuziStr= bsgstr.match(/\d+/g)[0];
        let shuziArr=shuziStr.split('')

        if(/百/.test(bsgstr)){
          shuziArr.forEach(strnum=>{
            let labstr=''
            if(dingnum==1){
              labstr=strnum+'XX'
            }else if(dingnum==2){

            }
           
            onlyNumbers.push(labstr)
          })
        }else if(/十/.test(bsgstr)){
          shuziArr.forEach(strnum=>{
            let labstr=''
            if(dingnum==1){
              labstr='X'+strnum+'X'
            }else if(dingnum==2){

            }
           
            onlyNumbers.push(labstr)
          })
        }else if(/个/.test(bsgstr)){
          shuziArr.forEach(strnum=>{
            let labstr=''
            if(dingnum==1){
              labstr='XX'+strnum
            }else if(dingnum==2){
              
            }
         
            onlyNumbers.push(labstr)
          })
        }
     
      })
      
    }
 
  } else{
    onlyNumbers = strArr.filter((item) => /^\d+$/.test(item));
  }
  
  let moneyObj={
    zhimoney:0,zumoney:0,fumoney:0,kuamoney:0,hemoney:0,
    dingmoney1:0,dingmoney2:0,duimoney:0
  }
  let engmeiju={
    
    zhimoney:'直',
    zumoney:'组',
    fumoney:'复',
    kuamoney:'跨',
    hemoney:'和',
    dingmoney1:'一定',
    dingmoney2:'二定',
    dumoney:'独',
    duimoney:'对'
  }

  if(item.includes('元')|| item.includes("米")){
    // 取出下注金额
    let filteredItems = strArr.filter(
        (item) => item.includes("元") || item.includes("米")|| item.includes("块")|| item.includes("倍")
      ); //筛选出包含"元"或"米"的项
    
      let numbersWithUnits = filteredItems.map((item) => {
        let number = item.replace(/\D/g, "");
        return number;
      });
    moneyObj.zhimoney=Number(numbersWithUnits[0]);
    moneyObj.zumoney=Number(numbersWithUnits[0]);
    moneyObj.fumoney=Number(numbersWithUnits[0]);
    moneyObj.kuamoney=Number(numbersWithUnits[0])
    moneyObj.hemoney=Number(numbersWithUnits[0])
    moneyObj.dingmoney1=Number(numbersWithUnits[0])
    moneyObj.dingmoney2=Number(numbersWithUnits[0])
    moneyObj.dumoney=Number(numbersWithUnits[0])
    moneyObj.duimoney=Number(numbersWithUnits[0])

  }else if(item.includes('倍')){
    let filteredItems = strArr.filter(
        (item) => item.includes("元") || item.includes("米")|| item.includes("块")|| item.includes("倍")
      ); //筛选出包含"元"或"米"的项
    
      let numbersWithUnits = filteredItems.map((item) => {
        let number = item.replace(/\D/g, "");
        return number;
      });
    moneyObj.zhimoney=Number(numbersWithUnits[0])*2;
    moneyObj.zumoney=Number(numbersWithUnits[0])*2;
    moneyObj.fumoney=Number(numbersWithUnits[0])*2;
    moneyObj.kuamoney=Number(numbersWithUnits[0])*2
    moneyObj.hemoney=Number(numbersWithUnits[0])*2
     moneyObj.dingmoney1=Number(numbersWithUnits[0])*2
    moneyObj.dingmoney2=Number(numbersWithUnits[0])*2
    moneyObj.dumoney=Number(numbersWithUnits[0])*2
     moneyObj.duimoney=Number(numbersWithUnits[0])*2
  }else if(this.checkCombination(item)){
    const chineseNumberMap = {
  '一': 1,
  '二': 2,
  '三': 3,
  '四': 4,
  '五': 5,
  '六': 6,
  '七': 7,
  '八': 8,
  '九': 9,
  '十': 10
};
 

    let str=this.checkCombination(item);
    let fsasd=str[0];
    fsasd=fsasd.replace(/[一二三四五六七八九十]/g, match => chineseNumberMap[match]);
    let typeIndex=fsasd.indexOf(typestr)
  for (const key in engmeiju) {
   
      if(engmeiju[key]==typestr){
        moneyObj[key]=Number(fsasd[typeIndex-1])*2
      
      }
      
    
  }
 
  }
    // console.log(moneyObj,879)

      return { onlyNumbers,
        ...moneyObj
      };
    },
    isNumeric(str) {
  return /^\d+$/.test(str);
},
    panduanAddOrCut(title,transData,badData){
      let currentItem=this.allTypeData.find(qwe=>qwe.title==title)
      if(currentItem){
        currentItem.arr=currentItem.arr.concat(transData)
        currentItem.arr1=currentItem.arr1.concat(badData)
      }else{
        let obj={title,arr:transData,show:true,arr1:badData}
        this.allTypeData.push(obj)
      }
      console.log(this.allTypeData,1037)
      // console.log( this.allTypeData)
      // 不符合规则的项目
      // let banCurrentItem=this.badtextArr.find(qwe=>qwe.title==title)
      // if(banCurrentItem){
      //   banCurrentItem.arr=banCurrentItem.arr.concat(badData)
      // }else{
      //   let obj={title,arr:badData}
      //   this.badtextArr.push(obj)
      // }
    },
    containsX(str) {
  return /x|X/.test(str);
},
    tipon(str){
      this.$confirm(str, '提示框', {
          confirmButtonText: '确定',
          cancelButtonText: '',
          showCancelButton:false,
          type: 'error'
        }).then(() => {
          
        }).catch(() => {
                   
        });
    },
    setLongTab(n) {
      this.changelongActive = n;
    },
    setKaiTab(n) {
      this.kaiActive = n;
    },
    resetAllInput(){
      this.badtextArr=[]
      this.allTypeData=[]
      this.textcontent=''
     
      this.resultData=[]
 
    },
    getBadNum(title){
      let num=0
      let baditem=this.badtextArr.find(ele=>ele.title==title)
      if(baditem){
        num=baditem.arr.length
      }
      return num
    },
    fengeStrData(str){
      let strArr=[]
      if(str.includes('.')){
       //  以.位分隔符
     strArr=str.split('.');
     }else if(str.includes(' ')){
      // 以空格位分隔符
      strArr=str.split(' ');
     }else if(str.includes('/')){
      // 以/位分隔符
      strArr=str.split('/');
     }else if(str.includes(',')){
      // 以,位分隔符
      strArr=str.split(',');
     }else if(str.includes('-')){
      // 以-位分隔符
      strArr=str.split('-');
     } else{
      strArr=[str]
     }
     return strArr
   
    }
  },
};
</script>
<style scoped>
.fuckli li{
  line-height: 20px;
  font-size: 13px;
}
.norule{
  color: brown;
  margin-right: 10px;
  text-decoration: line-through;
}
.dantuo-sc.h2zd_ld{
  
    /* max-height: 250px !important; */
}
.asdefda{
  padding: 10px;
  width: 100%;
  height: 292px;
  box-sizing: border-box;
}
.typeitem.selected{
  color: red;
}
.typeitem{
  margin-left:6px;
  font-size: 14px;
  font-weight: bold;
  line-height:30px ;
  cursor: pointer;
}
.font_bold{
  margin-right: 5px;
}
.flexinput{
  flex: 1;
}
.other_row.aoxin{
  padding: 1.4px;
  display: flex;
}
.type-f3d .greenball {
  /* background-image: url("../../assets/3d.png"); */
}
</style>